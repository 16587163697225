import { FC, ReactElement, memo, useCallback, useEffect, useMemo, useRef, useState } from "react"
import { useLocation, useHistory, Link } from "react-router-dom"
import {
  COLOR_BG_DARK,
  COLOR_BG_GRAY,
  COLOR_BG_GREEN,
  COLOR_BG_ORANGE,
  COLOR_BG_RED,
  CENTRAL_CONTAINER_MAX_WIDTH,
  parsePathForEntityUUID,
  pbUUIDToUuid,
  uuidToPbUUID,
  CENTRAL_CONTAINER_GAP,
} from "../utils/utils"
import { IImpactPoints, IKinematicPoint, MeanWithStd } from "../types"
import {
  Alert,
  Button,
  Col,
  Divider,
  Popconfirm,
  Row,
  Spin,
  Switch,
  Tooltip,
  Typography,
  message,
} from "antd"
import {
  normTypeElement,
  methodElement,
  isLabTestElement,
  sportsMatTypeElement,
} from "../components/commons-ts/tags"
import {
  FlexCol,
  FlexRow,
  RequirementsAlertPF,
  RequirementsAlertSM,
  UnderlinedSectionTitle,
} from "../components/commons-ts/common"
import { SimplifiedBreadcrumb } from "../components/commons-ts/simplifiedBreadcrumb"
import { Translated } from "../utils/translated"
import { DeleteOutlined } from "@ant-design/icons"
import { Label } from "../components/commons-ts/input"
import {
  ImpactChartKinematicAcceleration,
  ImpactChartKinematicDistance_Deflection,
  ImpactChartKinematicDistance_SecondarySuspense,
  ImpactChartKinematicVelocity_Resilience,
  ImpactChartKinematicVelocity_SecondarySuspense,
  ImpactChartRawAcceleration,
} from "../components/commons-ts/uplotCharts"
import uPlot from "uplot"
import { getEquipmentSportsMatThicknessMeanAndStd } from "./02_Equipment"
import { SliderButton } from "./Impact/SliderButton"
import { Impact, MethodTypeEN1177, NormType, UUID } from "../generated/proto-ts/main"
import { useUsercommContextBLE } from "../usercomm/local/ble/usercommProviderBLE"
import { useUsercommSetImpactParentBLE } from "../usercomm/local/ble/usercommAsyncRequestBLE"
import { DataTreeDrawer } from "../components/commons-ts/dataTreeDrawer"
import {
  ZoneImpactsTablePF_CFH,
  ZoneImpactsTablePF_ADQ,
} from "./Tables/03_ZoneImpacts/ZoneImpactsTable_PF"
import { ZoneImpactsTableTL } from "./Tables/03_ZoneImpacts/ZoneImpactsTable_TL"
import { ZoneImpactsTableAB } from "./Tables/03_ZoneImpacts/ZoneImpactsTable_AB"
import { ZoneImpactsTableSM } from "./Tables/03_ZoneImpacts/ZoneImpactsTable_SM"
import {
  EN_ISO_23659_TARGET_PERFORMANCE_FACTOR_ADEQUATE,
  EN_ISO_23659_TARGET_PERFORMANCE_FACTOR_TOREVIEW,
  IImpactKinematicHIC,
} from "../calculus/types"
import { getRequirementsSM } from "../calculus/calculus_SM"
import { getImpactKinematics, getSignedQuadraticSum } from "../calculus/kinematics"
import { TrampolineReferenceBarChart } from "../components/commons-ts/simpleCharts"
import { _calculatePerformanceFactor } from "../calculus/calculus_TL"
import {
  useUsercommDeleteImpactBimodal,
  useUsercommEquipmentBimodal,
  useUsercommImpactBimodal,
  useUsercommSiteBimodal,
  useUsercommZoneBimodal,
  useUsercommZoneImpactsBimodal,
} from "../usercomm/common/usercommAsyncRequestBimodal"
import { EntityTimestamps } from "../components/commons-ts/entityTimestamps"
import {
  decodeImpactDataPoints,
  offsetImpactDataPointsInplace,
} from "../usercomm/common/usercommUtils"
import { useTranslationContext } from "../providers/translationProvider"

const MeasuredValueLabel: FC<{
  children: ReactElement
}> = memo(({ children }) => {
  return (
    <div
      style={{
        fontSize: "0.9rem",
      }}
    >
      <Label>{children}</Label>
    </div>
  )
})

const MeasuredValueElement: FC<{
  children: JSX.Element
}> = memo(({ children }) => {
  return (
    <div
      style={{
        fontSize: "2rem",
        fontWeight: "bold",
      }}
    >
      {children}
    </div>
  )
})

const DividerVertical: FC = memo(() => {
  return (
    <div
      style={{
        border: `${COLOR_BG_GRAY} solid 1px`,
      }}
    />
  )
})

export const ImpactPage: FC = () => {
  const [antdMessageCtx, antdMessageCtxHolder] = message.useMessage()
  const { translated } = useTranslationContext()
  const location = useLocation()
  const history = useHistory()
  const memoImpactUUID = useMemo((): UUID | null => {
    let impactUUID = parsePathForEntityUUID(location.pathname)
    if (impactUUID === null) {
      return null
    }
    return uuidToPbUUID(impactUUID)
  }, [location.pathname])

  // Fetch zone
  const [zone, getZone] = useUsercommZoneBimodal()
  // Zone fields
  const [zoneUUIDStr, setZoneUUIDStr] = useState<string | null>(null) // use string to avoid effects re-firing on UUID ref change
  const [zoneName, setZoneName] = useState<string | null>(null)

  // Fetch equipment
  const [equipment, getEquipment] = useUsercommEquipmentBimodal()
  // Equipment fields
  const [equipmentUUIDStr, setEquipmentUUIDStr] = useState<string | null>(null) // use string to avoid effects re-firing on UUID ref change
  const [equipmentName, setEquipmentName] = useState<string | null>(null)

  // Fetch site
  const [site, getSite] = useUsercommSiteBimodal()
  // Site fields
  const [siteUUIDStr, setSiteUUIDStr] = useState<string | null>(null) // use string to avoid effects re-firing on UUID ref change
  const [siteName, setSiteName] = useState<string | null>(null)
  const [normType, setNormType] = useState<NormType | null>(null)
  const [method, setMethod] = useState<MethodTypeEN1177 | null>(null)
  const [isLabTest, setIsLabTest] = useState<boolean | null>(null)

  // Fetch impact
  // Will be called 3 times:
  //   - no axes no quadratic
  //   - quadratic only
  //   - axes only
  const [impact, getImpact] = useUsercommImpactBimodal()
  const [impactReceptionNumber, setImpactReceptionNumber] = useState<number>(0)

  // Impact fields
  const [impactHeightM, setImpactHeightM] = useState<number | null>(null)
  const [impactHIC, setImpactHIC] = useState<IImpactKinematicHIC | null>(null)
  const [impactGmax, setImpactGmax] = useState<number | null>(null)

  // Update impact (set parent)
  const [setImpactParentAck, setImpactParent] = useUsercommSetImpactParentBLE()
  // Delete impact
  const [deletedImpactAck, deleteImpact] = useUsercommDeleteImpactBimodal()

  const [releaseResetTrigger, setReleaseResetTrigger] = useState<number>(0)
  const [isReleaseLoading, setIsReleaseLoading] = useState<boolean>(false)
  const { bleIsConnected, hicRawMeasurementConsumable, emitDropHIC, consumeHICRawMeasurement } =
    useUsercommContextBLE()

  const [shouldDrawRequirements, setShouldDrawRequirements] = useState<boolean>(true)
  const mooSyncRef = useRef<uPlot.SyncPubSub>(uPlot.sync("moo"))

  // calculated
  const [impactDurationMS, setImpactDurationMS] = useState<number | null>(null)
  const [impactSamplingFrequency, setImpactSamplingFrequency] = useState<number | null>(null)
  const [impactInitialVelocity, setImpactInitialVelocity] = useState<number | null>(null)
  const [impactFinalVelocity, setImpactFinalVelocity] = useState<number | null>(null)
  const [impactResiliencePerc, setImpactResiliencePerc] = useState<number | null>(null)
  const [impactDeflectionDistanceMM, setImpactDeflectionDistanceMM] = useState<number | null>(null)
  const [impactDeflectionDistancePerc, setImpactDeflectionDistancePerc] = useState<number | null>(
    null,
  )
  const [impactDeflectionTimeMS, setImpactDeflectionTimeMS] = useState<number | null>(null)
  const [impactKinematicPoints, setImpactKinematicPoints] = useState<IKinematicPoint[] | null>(null)
  const [impactSecondarySuspenseHeightM, setImpactSecondarySuspenseHeightM] = useState<
    number | null
  >(null)
  const [impactSecondarySuspenseTimeMS, setImpactSecondarySuspenseTimeMS] = useState<number | null>(
    null,
  )

  const impactPointsRef = useRef<IImpactPoints | null>(null)

  const [impacts, getImpacts] = useUsercommZoneImpactsBimodal()
  const [impactIdx, setImpactIdx] = useState<number | null>(null)
  const [newImpactUUID, setNewImpactUUID] = useState<string | null>(null)

  useEffect(() => {
    if (impact === null) {
      return
    }
    setImpactReceptionNumber(0)
    impactPointsRef.current = null
  }, [memoImpactUUID])

  // // Fetch impact
  useEffect(() => {
    if (memoImpactUUID === null) {
      return
    }
    if (impactReceptionNumber > 2) {
      return
    }
    console.debug(
      `ImpactPage: fetching impact ${pbUUIDToUuid(memoImpactUUID)}, #${impactReceptionNumber}`,
    )
    let preprocessIncludeAxesPoints = false
    let preprocessIncludeQuadraticPoints = false
    let preprocessDifferentialEncodePoints = false
    if (impactReceptionNumber === 1) {
      preprocessIncludeQuadraticPoints = true
    } else if (impactReceptionNumber === 2) {
      preprocessIncludeAxesPoints = true
      preprocessDifferentialEncodePoints = true
    }
    getImpact(
      memoImpactUUID,
      preprocessIncludeAxesPoints,
      preprocessIncludeQuadraticPoints,
      preprocessDifferentialEncodePoints,
    )
  }, [memoImpactUUID, impactReceptionNumber])

  useEffect(() => {
    if (impact === null) {
      return
    }

    console.log(`ImpactPage: impact received:`, impact.toObject())
    setImpactHIC({
      hicMax: impact.impact_hic,
      hicDTms: 0,
      hicTms: 0,
    })
    // Impacts may have nil parents
    if (
      impact.zone_uuid !== null &&
      impact.zone_uuid !== undefined &&
      impact.zone_uuid.toArray().length > 0
    ) {
      setZoneUUIDStr(pbUUIDToUuid(impact.zone_uuid))
    }

    let [aq_points, ax_points, ay_points, az_points] = decodeImpactDataPoints(impact)
    offsetImpactDataPointsInplace(impact, ax_points, ay_points, az_points, aq_points)

    let _impactPoints: IImpactPoints | null = impactPointsRef.current
    if (_impactPoints === null) {
      _impactPoints = {
        aq_points,
        ax_points,
        ay_points,
        az_points,
      }
    } else {
      if (aq_points !== null && aq_points.length > 0) {
        _impactPoints.aq_points = aq_points
      }
      if (ax_points !== null && ax_points.length > 0) {
        _impactPoints.ax_points = ax_points
      }
      if (ay_points !== null && ay_points.length > 0) {
        _impactPoints.ay_points = ay_points
      }
      if (az_points !== null && az_points.length > 0) {
        _impactPoints.az_points = az_points
      }
    }
    impactPointsRef.current = _impactPoints
    if (
      _impactPoints.ax_points.length > 0 &&
      _impactPoints.ay_points.length > 0 &&
      _impactPoints.az_points.length > 0 &&
      _impactPoints.aq_points.length > 0
    ) {
      // all axes and quadratic points received, probably with the first impact reception if it came from Cloud
      setImpactReceptionNumber(3)
    } else {
      setImpactReceptionNumber((prev) => prev + 1)
    }

    // Only calculate kinematics if aq_points are present in the impact
    let f = impact.decimated_frequency
    if (f === 0) {
      f = impact.sampling_frequency
    }
    if (f === 0) {
      console.warn(`getImpactKinematics: f=0 Hz, using 1kHz as default`)
      f = 1e3
    }
    setImpactGmax(impact.impact_gmax)
    setImpactSamplingFrequency(impact.decimated_frequency)
    setImpactDurationMS(1e3 * impact.impact_duration)
    setImpactInitialVelocity(impact.impact_velocity_inward)
    setImpactFinalVelocity(impact.impact_velocity_outward)
    setImpactResiliencePerc(1e2 * impact.impact_resilience)
    setImpactDeflectionDistanceMM(1e3 * impact.impact_deflection)
    setImpactDeflectionTimeMS((1e3 * impact.impact_table_deflection_index) / f)
    setImpactHeightM(impact.impact_ffh)
    // setImpactSecondarySuspenseHeightM(impact.impact_rebounce_height)
    // setImpactSecondarySuspenseTimeMS((1e3 * impact.impact_table_rebounce_index) / f)
    if (aq_points !== null && aq_points.length > 0) {
      let calculatedKinematics = getImpactKinematics(impact, aq_points)
      if (calculatedKinematics === null) {
        return
      }
      setImpactKinematicPoints(calculatedKinematics.kinematicPoints)
      setImpactSecondarySuspenseHeightM(calculatedKinematics.secondarySuspenseHeightM)
      setImpactSecondarySuspenseTimeMS(calculatedKinematics.secondarySuspenseTimeMS)
      return
    }
  }, [impact])

  const memoSportsMatThicknessMeanAndStd = useMemo((): MeanWithStd | null => {
    if (equipment === null) {
      return null
    }
    return getEquipmentSportsMatThicknessMeanAndStd([
      equipment.sports_mat_thickness_side_one,
      equipment.sports_mat_thickness_side_two,
      equipment.sports_mat_thickness_side_three,
      equipment.sports_mat_thickness_side_four,
    ])
  }, [equipment])

  useEffect(() => {
    if (impactDeflectionDistanceMM === null || memoSportsMatThicknessMeanAndStd === null) {
      return
    }
    let thicknessMean: number | null = null
    thicknessMean = memoSportsMatThicknessMeanAndStd.mean
    setImpactDeflectionDistancePerc((impactDeflectionDistanceMM ?? 0) / thicknessMean)
  }, [impactDeflectionDistanceMM, memoSportsMatThicknessMeanAndStd])

  // // Fetch zone
  useEffect(() => {
    if (zoneUUIDStr === null) {
      return
    }
    getZone(uuidToPbUUID(zoneUUIDStr))
  }, [zoneUUIDStr])

  useEffect(() => {
    if (zone === null) {
      return
    }
    console.log(`ImpactPage: zone received:`, zone.toObject())
    setEquipmentUUIDStr(pbUUIDToUuid(zone.equipment_uuid))
    setZoneName(zone.zone_name)
  }, [zone])

  // // Fetch equipment
  useEffect(() => {
    if (equipmentUUIDStr === null) {
      return
    }
    getEquipment(uuidToPbUUID(equipmentUUIDStr))
  }, [equipmentUUIDStr])

  useEffect(() => {
    if (equipment === null) {
      return
    }
    console.log(`ImpactPage: equipment received:`, equipment.toObject())
    setSiteUUIDStr(pbUUIDToUuid(equipment.site_uuid))
    setEquipmentName(equipment.equipment_name)
  }, [equipment])

  // // Fetch site
  useEffect(() => {
    if (siteUUIDStr === null) {
      return
    }
    getSite(uuidToPbUUID(siteUUIDStr))
  }, [siteUUIDStr])

  useEffect(() => {
    if (site === null) {
      return
    }
    console.log(`ImpactPage: site received:`, site.toObject())
    setSiteName(site.site_name)
    setNormType(site.norm_type)
    setMethod(site.method_type_en_1177)
    setIsLabTest(site.is_lab_test)
  }, [site])

  // fetch zone impacts
  useEffect(() => {
    if (zoneUUIDStr === null || equipment === null || site === null) {
      return
    }
    getImpacts(uuidToPbUUID(zoneUUIDStr))
  }, [zoneUUIDStr, equipment, site])
  useEffect(() => {
    if (impacts === null) {
      console.log(`ImpactsPage: zone impacts received:`, impacts)
    }
  }, [impacts])

  // // New Impact reception
  // Impact reception
  useEffect(() => {
    if (hicRawMeasurementConsumable === null) {
      return
    }
    consumeHICRawMeasurement()
    console.log(`ImpactPage: new impact received:`, hicRawMeasurementConsumable)
    if (zoneUUIDStr === null) {
      return
    }
    setNewImpactUUID(pbUUIDToUuid(hicRawMeasurementConsumable))
    setImpactParent(hicRawMeasurementConsumable, uuidToPbUUID(zoneUUIDStr), Date.now())
  }, [zoneUUIDStr, hicRawMeasurementConsumable])

  useEffect(() => {
    if (setImpactParentAck === null || newImpactUUID === null) {
      return
    }
    antdMessageCtx.success(translated("Impact received!"))
    setIsReleaseLoading(false)
    setReleaseResetTrigger(Date.now())
    if (zoneUUIDStr !== null) {
      getImpacts(uuidToPbUUID(zoneUUIDStr))
    }
    setTimeout(() => {
      history.push(`/impacts/${newImpactUUID}`)
    }, 1000)
  }, [newImpactUUID, zoneUUIDStr, setImpactParentAck])

  const memoNormTypeElement = useMemo(() => {
    return normTypeElement(normType)
  }, [normType])

  const memoMethodElement = useMemo(() => {
    if (normType === NormType.EN_12503) {
      return null
    }
    return methodElement(method)
  }, [normType, method])

  const memoSportsMatTypeElement = useMemo(() => {
    if (equipment === null || normType !== NormType.EN_12503) {
      return null
    }
    return sportsMatTypeElement(equipment.sports_mat_type)
  }, [equipment, normType])

  const memoIsLabTestElement = useMemo(() => {
    return isLabTestElement(isLabTest)
  }, [isLabTest])

  const memoImpactName = useMemo(() => {
    return (
      <>
        <Translated keyEn="Impact" /> {impactIdx !== null && <># {impactIdx ?? 0 + 1}</>}
      </>
    )
  }, [impact, impactIdx])

  const onDeleteImpact = useCallback(
    async (impact: Impact | null) => {
      if (impact === null) {
        return
      }
      deleteImpact(impact.uuid)
    },
    [impact],
  )
  useEffect(() => {
    if (deletedImpactAck === null) {
      return
    }
    antdMessageCtx.info(translated("Impact deleted!"))
    if (zoneUUIDStr !== null) {
      getImpacts(uuidToPbUUID(zoneUUIDStr))
    }
  }, [deletedImpactAck, zoneUUIDStr])

  const memoImpactsTable = useMemo(() => {
    let impactUUIDStr: string | null = null
    if (memoImpactUUID !== null) {
      impactUUIDStr = pbUUIDToUuid(memoImpactUUID)
    }
    if (normType === NormType.EN_12503) {
      return (
        <ZoneImpactsTableSM
          impacts={impacts}
          currentImpactUUID={impactUUIDStr}
          equipment={equipment}
          onDeleteImpact={onDeleteImpact}
        />
      )
    } else if (normType === NormType.EN_14960) {
      return (
        <ZoneImpactsTableAB
          impacts={impacts}
          currentImpactUUID={impactUUIDStr}
          zone={zone}
          onDeleteImpact={onDeleteImpact}
        />
      )
    } else if (normType === NormType.EN_ISO_23659) {
      let impactorWeight: number | null = null
      if (equipment !== null) {
        impactorWeight = equipment.trampoline_impactor_weight
      }
      if (impactorWeight === null || impactorWeight === 0) {
        impactorWeight = 6.25
      }
      return (
        <ZoneImpactsTableTL
          impacts={impacts}
          currentImpactUUID={impactUUIDStr}
          equipmentImpactorWeight={impactorWeight}
          onDeleteImpact={onDeleteImpact}
        />
      )
    } else if (normType === NormType.EN_1177) {
      if (method === MethodTypeEN1177.CRITICAL_FALL_HEIGHT_DETERMINATION) {
        return (
          <ZoneImpactsTablePF_CFH
            impacts={impacts}
            currentImpactUUID={impactUUIDStr}
            zone={zone}
            onDeleteImpact={onDeleteImpact}
          />
        )
      }
      return (
        <ZoneImpactsTablePF_ADQ
          impacts={impacts}
          currentImpactUUID={impactUUIDStr}
          onDeleteImpact={onDeleteImpact}
        />
      )
    }

    return null
  }, [memoImpactUUID, impacts, normType, method, zone, equipment])

  const memoChartColStyle = useMemo(() => {
    return { height: 300, marginBottom: 50 }
  }, [])

  const memoRequirementsSM = useMemo(() => {
    if (equipment === null) {
      return null
    }
    let requirements = getRequirementsSM(equipment.sports_mat_type)
    return requirements
  }, [equipment])

  const memoImpactGmaxElement = useMemo(() => {
    let color = COLOR_BG_GRAY
    let value = "N/A"
    if (impactGmax === null) {
      return <span style={{ color }}>{value}</span>
    }
    value = impactGmax.toFixed(1)
    if (normType === NormType.EN_12503) {
      if (memoRequirementsSM === null || memoRequirementsSM.gmax === null) {
        if (impactGmax <= 200) {
          color = COLOR_BG_GREEN
        } else {
          color = COLOR_BG_RED
        }
        return <span style={{ color }}>{value}g</span>
      }
      if (impactGmax <= memoRequirementsSM.gmax) {
        color = COLOR_BG_GREEN
      } else {
        color = COLOR_BG_RED
      }
    }
    return <span style={{ color }}>{value}g</span>
  }, [normType, impactGmax, memoRequirementsSM])

  const memoImpactHICElement = useMemo(() => {
    let color = COLOR_BG_GRAY
    let value = "N/A"
    if (impactHIC === null) {
      return <span style={{ color }}>{value}</span>
    }
    value = impactHIC.hicMax.toFixed(1)
    if (impactHIC.hicMax <= 1000) {
      color = COLOR_BG_GREEN
    }
    return <span style={{ color }}>{value}</span>
  }, [impactHIC])

  const memoImpactResilienceElement = useMemo(() => {
    let color = COLOR_BG_GRAY
    let value = "N/A"
    if (impactResiliencePerc === null) {
      return <span style={{ color }}>{value}</span>
    }
    value = impactResiliencePerc.toFixed(0)
    if (memoRequirementsSM === null || memoRequirementsSM.resiliencePerc === null) {
      return <span style={{ color }}>{value}%</span>
    }
    let [threshLow, threshHigh] = memoRequirementsSM.resiliencePerc
    console.log(`ImpactPage: threshLow: ${threshLow}; threshHigh: ${threshHigh}; value: ${value}`)
    if (impactResiliencePerc < threshHigh) {
      if (threshLow === null) {
        color = COLOR_BG_GREEN
        return <span style={{ color }}>{value}%</span>
      }
      if (impactResiliencePerc > threshLow) {
        color = COLOR_BG_GREEN
        return <span style={{ color }}>{value}%</span>
      } else {
        color = COLOR_BG_RED
        return <span style={{ color }}>{value}%</span>
      }
    }
    color = COLOR_BG_RED
    return <span style={{ color }}>{value}%</span>
  }, [impactResiliencePerc, memoRequirementsSM])

  const memoImpactDeformationDistanceElement = useMemo(() => {
    let color = COLOR_BG_GRAY
    let value = "N/A"
    if (impactDeflectionDistanceMM === null) {
      return <span style={{ color }}>{value}</span>
    }
    value = impactDeflectionDistanceMM.toFixed(1)
    if (memoRequirementsSM === null || memoRequirementsSM.deformationDistanceMM === null) {
      return <span style={{ color }}>{value}</span>
    }
    if (impactDeflectionDistanceMM <= memoRequirementsSM.deformationDistanceMM) {
      color = COLOR_BG_GREEN
    } else {
      color = COLOR_BG_RED
    }
    return <span style={{ color }}>{value}</span>
  }, [impactDeflectionDistanceMM, memoRequirementsSM])

  const memoImpactDeformationPercElement = useMemo(() => {
    let color = COLOR_BG_GRAY
    let value = "N/A"
    if (impactDeflectionDistancePerc === null) {
      return <span style={{ color }}>{value}</span>
    }
    value = impactDeflectionDistancePerc.toFixed(0)
    if (memoRequirementsSM === null || memoRequirementsSM.deformationDistancePerc === null) {
      return (
        <Tooltip overlay={<>{impactDeflectionDistanceMM?.toFixed(0)} mm</>}>
          <span style={{ color }}>{value}%</span>
        </Tooltip>
      )
    }
    if (impactDeflectionDistancePerc <= memoRequirementsSM.deformationDistancePerc) {
      color = COLOR_BG_GREEN
    } else {
      color = COLOR_BG_RED
    }
    return (
      <Tooltip overlay={<>{impactDeflectionDistanceMM} mm</>}>
        <span style={{ color }}>{value}%</span>
      </Tooltip>
    )
  }, [impactDeflectionDistancePerc, impactDeflectionDistanceMM, memoRequirementsSM])

  const memoImpactVelocitiesMSElement = useMemo(() => {
    let color = COLOR_BG_DARK
    let value = "N/A"
    if (impactInitialVelocity === null || impactFinalVelocity === null) {
      return <span style={{ color }}>{value}</span>
    }
    let initialVelocity = impactInitialVelocity.toFixed(2)
    let finalVelocity = impactFinalVelocity.toFixed(2)
    return (
      <span style={{ color }}>
        {initialVelocity}&nbsp;→&nbsp;{finalVelocity}
      </span>
    )
  }, [impactInitialVelocity, impactFinalVelocity])

  const memoImpactVelocitiesKMHElement = useMemo(() => {
    let color = COLOR_BG_DARK
    let value = "N/A"
    if (impactInitialVelocity === null || impactFinalVelocity === null) {
      return <span style={{ color }}>{value}</span>
    }
    let initialVelocity = (3.6 * impactInitialVelocity).toFixed(1)
    let finalVelocity = (3.6 * impactFinalVelocity).toFixed(1)
    return (
      <span style={{ color }}>
        {initialVelocity}&nbsp;→&nbsp;{finalVelocity}&nbsp;km/h
      </span>
    )
  }, [impactInitialVelocity, impactFinalVelocity])

  const memoImpactDurationElement = useMemo(() => {
    let color = COLOR_BG_DARK
    let value = "N/A"
    if (impactDurationMS === null) {
      return <span style={{ color }}>{value}</span>
    }
    value = impactDurationMS.toFixed(0)
    return <span style={{ color }}>{value}ms</span>
  }, [impactDurationMS])

  const memoImpactSamplingFrequency = useMemo(() => {
    let color = COLOR_BG_DARK
    let value = "N/A"
    if (impactSamplingFrequency === null) {
      return <span style={{ color }}>{value}</span>
    }
    value = (impactSamplingFrequency / 1000).toFixed(0)
    return <span style={{ color }}>{value} kHz</span>
  }, [impactSamplingFrequency])

  const memoImpactHeightElement = useMemo(() => {
    let color = COLOR_BG_DARK
    let value = "N/A"
    if (impactHeightM === null) {
      return <span style={{ color }}>{value}</span>
    }
    value = impactHeightM.toFixed(2)
    return <span style={{ color }}>{value}m</span>
  }, [impactHeightM])

  const memoImpactTrampolinePerformanceFactorValue = useMemo(() => {
    if (
      impact === null ||
      impactSecondarySuspenseHeightM === null ||
      impactGmax === null ||
      impactGmax === 0
    ) {
      return null
    }
    return _calculatePerformanceFactor(impactSecondarySuspenseHeightM, impactGmax)
  }, [impact, impactSecondarySuspenseHeightM, impactGmax])

  const memoImpactTrampolinePerformanceFactorElement = useMemo(() => {
    let color = COLOR_BG_DARK
    let value = "N/A"
    if (memoImpactTrampolinePerformanceFactorValue === null) {
      return <span style={{ color }}>{value}</span>
    }

    if (
      memoImpactTrampolinePerformanceFactorValue <= EN_ISO_23659_TARGET_PERFORMANCE_FACTOR_ADEQUATE
    ) {
      color = COLOR_BG_GREEN
    } else if (
      memoImpactTrampolinePerformanceFactorValue <= EN_ISO_23659_TARGET_PERFORMANCE_FACTOR_TOREVIEW
    ) {
      color = COLOR_BG_ORANGE
    } else {
      color = COLOR_BG_RED
    }

    value = memoImpactTrampolinePerformanceFactorValue.toFixed(0)
    return <span style={{ color }}>{value}</span>
  }, [memoImpactTrampolinePerformanceFactorValue])

  const memoEquipmentTrampolineImpactorWeight = useMemo(() => {
    if (equipment === null) {
      return null
    }
    let impactorWeight = equipment.trampoline_impactor_weight
    if (impactorWeight === null || impactorWeight === 0) {
      impactorWeight = 6.25
    }
    return impactorWeight
  }, [equipment])

  const memoImpactTrampolinePerformanceFactorEq20Value = useMemo((): number | null => {
    if (
      memoImpactTrampolinePerformanceFactorValue === null ||
      impactHeightM === null ||
      memoEquipmentTrampolineImpactorWeight === null
    ) {
      return null
    }
    return (
      (20 / memoEquipmentTrampolineImpactorWeight / impactHeightM) *
      memoImpactTrampolinePerformanceFactorValue
    )
  }, [
    memoImpactTrampolinePerformanceFactorValue,
    impactHeightM,
    memoEquipmentTrampolineImpactorWeight,
  ])

  const memoImpactTrampolinePerformanceFactorEq20KGFMElement = useMemo(() => {
    let color = COLOR_BG_DARK
    let value = "N/A"
    if (memoImpactTrampolinePerformanceFactorEq20Value === null) {
      return <span style={{ color }}>{value}</span>
    }

    if (
      memoImpactTrampolinePerformanceFactorEq20Value <=
      EN_ISO_23659_TARGET_PERFORMANCE_FACTOR_ADEQUATE
    ) {
      color = COLOR_BG_GREEN
    } else if (
      memoImpactTrampolinePerformanceFactorEq20Value <=
      EN_ISO_23659_TARGET_PERFORMANCE_FACTOR_TOREVIEW
    ) {
      color = COLOR_BG_ORANGE
    } else {
      color = COLOR_BG_RED
    }

    value = memoImpactTrampolinePerformanceFactorEq20Value.toFixed(0)
    return <span style={{ color }}>{value}</span>
  }, [memoImpactTrampolinePerformanceFactorEq20Value])

  const memoImpactSecondarySuspenseHeightMElement = useMemo(() => {
    let color = COLOR_BG_GRAY
    let value = "N/A"
    if (impactSecondarySuspenseHeightM === null) {
      return <span style={{ color }}>{value}</span>
    }
    value = impactSecondarySuspenseHeightM.toFixed(2)
    if (impactHeightM !== null && impactHeightM > 0) {
      let valuePerc = (100 * impactSecondarySuspenseHeightM) / impactHeightM
      return (
        <span style={{ color }}>
          {value}m&nbsp;({valuePerc.toFixed(0)}%)
        </span>
      )
    } else {
      return <span style={{ color }}>{value}m</span>
    }
  }, [impactSecondarySuspenseHeightM, impactHeightM])

  const memoRequirementsAlert = useMemo(() => {
    if (normType === NormType.EN_12503 && equipment !== null) {
      return <RequirementsAlertSM smType={equipment.sports_mat_type} />
    } else if (normType === NormType.EN_1177) {
      return <RequirementsAlertPF />
    } else if (normType === NormType.EN_ISO_23659) {
      return (
        <FlexCol>
          <TrampolineReferenceBarChart
            performanceFactor={memoImpactTrampolinePerformanceFactorEq20Value}
          />
          <Alert
            message={
              <>
                <Translated keyEn="Trampoline Performance Factor (PF) is evaluated according to" />
                &nbsp;
                <b>Annex E of EN ISO 23659</b> and it is basically <b>RebounceHeight / Gmax</b>.
                <br />
                <br />
                <Translated keyEn="Performance trampolines are those with " />
                <b>PF &gt; 95</b>
                &nbsp;
                <Translated keyEn="while ordinary consumer trampolines should have" />
                <b>&nbsp;PF &lt; 85</b>.
                <br />
                <Translated keyEn="Intermediate values should be reviewed" />.
              </>
            }
          />
        </FlexCol>
      )
    } else {
      return null
    }
  }, [normType, equipment, memoImpactTrampolinePerformanceFactorValue])

  const memoImpactCharts_SM = useMemo(() => {
    if (impactSamplingFrequency === null) {
      return null
    }
    return (
      <Row gutter={[10, 10]} justify="center">
        <Col xs={24} style={memoChartColStyle}>
          <ImpactChartRawAcceleration
            impactPoints={impactPointsRef.current}
            impactFrequency={impactSamplingFrequency}
            mooSync={mooSyncRef.current}
          />
        </Col>
        <Col xs={24} md={8} style={memoChartColStyle}>
          <ImpactChartKinematicAcceleration
            kinematicPoints={impactKinematicPoints}
            maxAcceptableG={memoRequirementsSM === null ? null : memoRequirementsSM.gmax}
            mooSync={mooSyncRef.current}
            shouldDrawRequirements={shouldDrawRequirements}
          />
        </Col>
        <Col xs={24} md={8} style={memoChartColStyle}>
          <ImpactChartKinematicVelocity_Resilience
            kinematicPoints={impactKinematicPoints}
            maxAcceptableResiliencePerc={
              memoRequirementsSM !== null && memoRequirementsSM.resiliencePerc !== null
                ? memoRequirementsSM.resiliencePerc[1]
                : null
            }
            mooSync={mooSyncRef.current}
            shouldDrawRequirements={shouldDrawRequirements}
          />
        </Col>
        <Col xs={24} md={8} style={memoChartColStyle}>
          <ImpactChartKinematicDistance_Deflection
            kinematicPoints={impactKinematicPoints}
            deflectionDistanceMM={impactDeflectionDistanceMM}
            deflectionTimeMs={impactDeflectionTimeMS}
            totalDepth={memoSportsMatThicknessMeanAndStd}
            maxAcceptableDeformationDist={
              memoRequirementsSM === null ? null : memoRequirementsSM.deformationDistanceMM
            }
            maxAcceptableDeformationPerc={
              memoRequirementsSM === null ? null : memoRequirementsSM.deformationDistancePerc
            }
            mooSync={mooSyncRef.current}
            shouldDrawRequirements={shouldDrawRequirements}
          />
        </Col>
      </Row>
    )
  }, [
    impactKinematicPoints,
    shouldDrawRequirements,
    memoRequirementsSM,
    memoChartColStyle,
    memoSportsMatThicknessMeanAndStd,
    impactDeflectionDistanceMM,
    impactDeflectionTimeMS,
    impactReceptionNumber,
    impactSamplingFrequency,
  ])

  const memoImpactCharts_TL = useMemo(() => {
    if (impactSamplingFrequency === null) {
      return null
    }
    return (
      <Row gutter={[10, 10]} justify="center">
        <Col xs={24} style={memoChartColStyle}>
          <ImpactChartRawAcceleration
            impactPoints={impactPointsRef.current}
            impactFrequency={impactSamplingFrequency}
            mooSync={mooSyncRef.current}
          />
        </Col>
        <Col xs={24} md={8} style={memoChartColStyle}>
          <ImpactChartKinematicAcceleration
            kinematicPoints={impactKinematicPoints}
            maxAcceptableG={null}
            mooSync={mooSyncRef.current}
            shouldDrawRequirements={shouldDrawRequirements}
          />
        </Col>
        <Col xs={24} md={8} style={memoChartColStyle}>
          <ImpactChartKinematicVelocity_SecondarySuspense
            kinematicPoints={impactKinematicPoints}
            mooSync={mooSyncRef.current}
          />
        </Col>
        <Col xs={24} md={8} style={memoChartColStyle}>
          <ImpactChartKinematicDistance_SecondarySuspense
            kinematicPoints={impactKinematicPoints}
            deflectionDistanceMM={impactDeflectionDistanceMM}
            deflectionTimeMs={impactDeflectionTimeMS}
            secondarySuspenseHeightM={impactSecondarySuspenseHeightM}
            secondarySuspenseTimeMS={impactSecondarySuspenseTimeMS}
            mooSync={mooSyncRef.current}
          />
        </Col>
      </Row>
    )
  }, [
    impactKinematicPoints,
    shouldDrawRequirements,
    memoChartColStyle,
    impactSecondarySuspenseHeightM,
    impactSecondarySuspenseTimeMS,
    impactDeflectionDistanceMM,
    impactDeflectionTimeMS,
    impactReceptionNumber,
    impactSamplingFrequency,
  ])

  const memoImpactCharts_PF = useMemo(() => {
    if (impactSamplingFrequency === null) {
      return null
    }
    return (
      <Row gutter={[10, 10]} justify="center">
        <Col xs={24} style={memoChartColStyle}>
          <ImpactChartRawAcceleration
            impactPoints={impactPointsRef.current}
            impactFrequency={impactSamplingFrequency}
            mooSync={mooSyncRef.current}
          />
        </Col>
      </Row>
    )
  }, [memoChartColStyle, impactReceptionNumber, impactSamplingFrequency])

  const memoImpactChartsElement = useMemo(() => {
    if (normType === null) {
      return null
    }
    if (normType === NormType.EN_12503) {
      return memoImpactCharts_SM
    } else if (normType === NormType.EN_ISO_23659) {
      return memoImpactCharts_TL
    } else {
      return memoImpactCharts_PF
    }
  }, [normType, memoImpactCharts_SM, memoImpactCharts_TL, memoImpactCharts_PF])

  const memoMeasuredValuesElement = useMemo(() => {
    if (normType === null) {
      return null
    }
    let rawValuesCols: JSX.Element[] = [
      <Col key="impact-page-raw-value-col-fall-height">
        <FlexRow>
          <FlexCol style={{ gap: 0 }}>
            <MeasuredValueLabel>
              <Translated keyEn="Fall height, m" />
            </MeasuredValueLabel>
            <MeasuredValueElement>{memoImpactHeightElement}</MeasuredValueElement>
          </FlexCol>
          <DividerVertical />
          <FlexCol style={{ gap: 0 }}>
            <MeasuredValueLabel>
              <Translated keyEn="Duration, ms" />
            </MeasuredValueLabel>
            <MeasuredValueElement>{memoImpactDurationElement}</MeasuredValueElement>
          </FlexCol>
          <DividerVertical />
          <FlexCol style={{ gap: 0 }}>
            <MeasuredValueLabel>
              <Translated keyEn="Sampling Freq, kHz" />
            </MeasuredValueLabel>
            <MeasuredValueElement>{memoImpactSamplingFrequency}</MeasuredValueElement>
          </FlexCol>
        </FlexRow>
      </Col>,
    ]
    if (normType === NormType.EN_12503) {
      rawValuesCols.push(
        <Col xs={8} key="impact-page-raw-value-col-velocity">
          <FlexCol style={{ gap: 0 }}>
            <MeasuredValueLabel>
              <Translated keyEn="Velocity, m/s" />
            </MeasuredValueLabel>
            <MeasuredValueElement>{memoImpactVelocitiesMSElement}</MeasuredValueElement>
            <span>{memoImpactVelocitiesKMHElement}</span>
          </FlexCol>
        </Col>,
      )
    }

    let calculatedValuesCols: JSX.Element[] = []
    if (normType === NormType.EN_1177) {
      calculatedValuesCols.push(
        <Col key="impact-page-raw-value-col-en-1177">
          <FlexRow>
            <FlexCol style={{ gap: 0 }}>
              <MeasuredValueLabel>
                <Translated keyEn="Gmax, g" />
              </MeasuredValueLabel>
              <MeasuredValueElement>{memoImpactGmaxElement}</MeasuredValueElement>
            </FlexCol>
            <DividerVertical />
            <FlexCol style={{ gap: 0 }}>
              <MeasuredValueLabel>
                <Translated keyEn="HIC" />
              </MeasuredValueLabel>
              <MeasuredValueElement>{memoImpactHICElement}</MeasuredValueElement>
            </FlexCol>
          </FlexRow>
        </Col>,
      )
    } else if (normType === NormType.EN_12503) {
      calculatedValuesCols.push(
        <Col key="impact-page-raw-value-col-en-12503">
          <FlexRow>
            <FlexCol>
              <MeasuredValueLabel>
                <Translated keyEn="Gmax, g" />
              </MeasuredValueLabel>
              <MeasuredValueElement>{memoImpactGmaxElement}</MeasuredValueElement>
            </FlexCol>
            <DividerVertical />
            <FlexCol style={{ gap: 0 }}>
              <MeasuredValueLabel>
                <Translated keyEn="Deflection, mm" />
              </MeasuredValueLabel>
              <MeasuredValueElement>{memoImpactDeformationDistanceElement}</MeasuredValueElement>
            </FlexCol>
            {/* <DividerVertical />
                        <FlexCol style={{ gap: 0 }}>
                            <Label>
                                <Translated keyEn="Deflection. %" />
                            </Label>
                            <MeasuredValueElement>
                                {memoImpactDeformationPercElement}
                            </MeasuredValueElement>
                        </FlexCol> */}
            <DividerVertical />
            <FlexCol style={{ gap: 0 }}>
              <MeasuredValueLabel>
                <Translated keyEn="Resilience %" />
              </MeasuredValueLabel>
              <MeasuredValueElement>{memoImpactResilienceElement}</MeasuredValueElement>
            </FlexCol>
          </FlexRow>
        </Col>,
      )
    } else if (normType === NormType.EN_ISO_23659) {
      calculatedValuesCols.push(
        <Col key="impact-page-raw-value-col-en-iso-23659">
          <FlexRow>
            <FlexCol style={{ gap: 0 }}>
              <MeasuredValueLabel>
                <Translated keyEn="Gmax, g" />
              </MeasuredValueLabel>
              <MeasuredValueElement>{memoImpactGmaxElement}</MeasuredValueElement>
            </FlexCol>
            <DividerVertical />
            <FlexCol style={{ gap: 0 }}>
              <MeasuredValueLabel>
                <Translated keyEn="Rebounce height, m" />
              </MeasuredValueLabel>
              <MeasuredValueElement>
                {memoImpactSecondarySuspenseHeightMElement}
              </MeasuredValueElement>
            </FlexCol>
            <DividerVertical />
            <FlexCol style={{ gap: 0 }}>
              <MeasuredValueLabel>
                <Tooltip overlay={<Translated keyEn="Performance Factor" />}>
                  <span>
                    <Translated keyEn="PF" />, km/g
                  </span>
                </Tooltip>
              </MeasuredValueLabel>
              <MeasuredValueElement>
                {memoImpactTrampolinePerformanceFactorElement}
              </MeasuredValueElement>
            </FlexCol>
            <DividerVertical />
            <FlexCol style={{ gap: 0 }}>
              <MeasuredValueLabel>
                <Tooltip overlay={<Translated keyEn="Performance Factor Equivalent 20 kgfm" />}>
                  <span>
                    <Translated keyEn="PF eq 20 kgfm" />, km/g
                  </span>
                </Tooltip>
              </MeasuredValueLabel>
              <MeasuredValueElement>
                {memoImpactTrampolinePerformanceFactorEq20KGFMElement}
              </MeasuredValueElement>
            </FlexCol>
          </FlexRow>
        </Col>,
      )
    }

    return (
      <div id="measured_values">
        <UnderlinedSectionTitle>
          <Translated keyEn="Measured values" />
        </UnderlinedSectionTitle>
        <Row
          gutter={[10, 10]}
          style={{
            marginTop: 20,
            justifyContent: "space-between",
          }}
        >
          {rawValuesCols}
          <Col xs={24} /> {/* Spacer */}
          {calculatedValuesCols}
        </Row>
      </div>
    )
  }, [
    normType,
    zoneUUIDStr,
    memoImpactHeightElement,
    memoImpactDurationElement,

    memoImpactVelocitiesMSElement,
    memoImpactVelocitiesKMHElement,

    memoImpactGmaxElement,
    memoImpactHICElement,

    memoImpactDeformationDistanceElement,
    memoImpactDeformationPercElement,
    memoImpactResilienceElement,

    memoImpactSecondarySuspenseHeightMElement,
    memoImpactTrampolinePerformanceFactorElement,
  ])

  const onReleaseCB = useCallback(() => {
    if (!bleIsConnected) {
      antdMessageCtx.warning(translated("No connection to the station, please double check!"))
      return
    }
    emitDropHIC()
    setIsReleaseLoading(true)
    setTimeout(() => {
      setIsReleaseLoading(false)
      setReleaseResetTrigger(Date.now())
    }, 10e3)
  }, [bleIsConnected])

  if (impact === null) {
    return (
      <FlexCol
        style={{
          width: "100%",
          height: "60vh",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Spin size="large" />
      </FlexCol>
    )
  }

  return (
    <>
      {antdMessageCtxHolder}
      <FlexCol
        style={{
          width: "100%",
          margin: "auto",
          maxWidth: CENTRAL_CONTAINER_MAX_WIDTH,
          gap: CENTRAL_CONTAINER_GAP,
        }}
      >
        <SimplifiedBreadcrumb
          previousItems={[
            {
              href: `/sites/${siteUUIDStr}`,
              label: siteName,
            },
            {
              href: `/equipments/${equipmentUUIDStr}`,
              label: equipmentName,
            },
            {
              href: `/zones/${zoneUUIDStr}`,
              label: zoneName,
            },
          ]}
          currentItem={{ label: memoImpactName }}
        />
        <Row
          justify="space-between"
          style={{
            marginTop: "1rem",
          }}
        >
          <Col>
            <FlexCol style={{ gap: 0 }}>
              <Label>
                <Translated keyEn="Impact results" />
              </Label>
              <Typography.Text
                style={{
                  fontSize: "2rem",
                }}
              >
                {memoImpactName}
              </Typography.Text>
            </FlexCol>
          </Col>
          <Col>
            <FlexCol style={{ alignItems: "end" }}>
              {memoNormTypeElement}
              {memoMethodElement}
              {memoIsLabTestElement}
              {memoSportsMatTypeElement}
            </FlexCol>
          </Col>
        </Row>
        {/* Requirements */}
        <div>
          <UnderlinedSectionTitle>
            <Translated keyEn="Requirements" />
          </UnderlinedSectionTitle>
          {memoRequirementsAlert}
        </div>
        {/* Measured values (impact data) */}
        {memoMeasuredValuesElement}
        {/* Debug */}
        {/* <div>
                    <Row gutter={[10, 10]}>
                        <Col>
                            <Button
                                type="primary"
                                onClick={() => onDebugRecalculate()}
                            >
                                Recalulate!
                            </Button>
                        </Col>
                    </Row>
                </div> */}
        {/* Impact chart */}
        {memoImpactChartsElement}
        {/* <Row gutter={[10, 10]} style={{ marginTop: 10 }}>
          <Col>
            <FlexRow>
              {" "}
              <span>
                <Translated keyEn="Draw Requirements" />
              </span>
              <Switch
                checked={shouldDrawRequirements}
                onChange={(checked) => setShouldDrawRequirements(checked)}
              />
            </FlexRow>
          </Col>
        </Row> */}
        {/* Other impacts table */}
        <div>
          <Label>
            <Translated keyEn="Other impacts" />
          </Label>
          {memoImpactsTable}
        </div>

        {/* New impact slider button */}
        <div className="flex-col -mx-3">
          <SliderButton
            onReleaseHIC={onReleaseCB}
            isLoading={isReleaseLoading}
            resetTrigger={releaseResetTrigger}
          />
        </div>
        {/* Delete & End */}
        <FlexRow
          style={{
            alignItems: "center",
            alignSelf: "flex-end",
          }}
        >
          <Popconfirm
            title={
              <>
                <Translated keyEn="Are you sure you want to delete this impact" />?
              </>
            }
            onConfirm={() => {
              onDeleteImpact(impact)
            }}
          >
            <Button type="link" danger icon={<DeleteOutlined />} size="small">
              <span
                style={{
                  textTransform: "uppercase",
                  fontSize: "0.8rem",
                }}
              >
                <Translated keyEn="Delete" />
              </span>
            </Button>
          </Popconfirm>
          <div
            style={{
              width: 200,
            }}
          >
            <Link to={`/zones/${zoneUUIDStr}`}>
              <Button type="primary" size="large" block>
                <span
                  style={{
                    textTransform: "uppercase",
                  }}
                >
                  <Translated keyEn="End" />
                </span>
              </Button>
            </Link>
          </div>
        </FlexRow>
        <EntityTimestamps entity={impact} />
      </FlexCol>
      <DataTreeDrawer
        site={site}
        selectedKey={memoImpactUUID ? pbUUIDToUuid(memoImpactUUID) : null}
      />
    </>
  )
}
